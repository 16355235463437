import React, { useState, useEffect, useRef } from 'react';
import api from "../../services/api";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import ProductCard from "../../components/ProductCard";
import Footer from "../../components/Footer";

import {ContainerHome, Title} from "./style.js";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { initGA } from '../../services/googleAnalytics4.js';

const Home = (  ) => {
  
  document.title = "Home | Bélica Militar"  

  useEffect(() => {
    initGA();
  }, []);

  
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    const dataFilter = { 
      "ID_DM005": "",
      "ID_DM013": "",
      "ID_DM013_PAI": "",
      "NM_PRODUTO": "",
      "IN_SITUACAO": "A",
      "CD_PRODUTO": "",
      "IN_DESTAQUE": "S",
      "ORDER_BY": true
    }
    
      api
      .post("filter_products", dataFilter)
      .then((response) => {
        setProductList(response.data)
      })
      .catch((err) => {
        console.log(err)
      });
    }, []);


  return (
    <>
      
      <Header/>
      <Banner/>
      <ContainerHome>
        <Container className="conShadown">
          <Title>Destaques</Title>
          <Row>
            {productList.map((product, index) => (
                <ProductCard idDM005={product.ID_DM005} name={product.NM_PRODUTO} description={product.NM_PRODUTO} price={product.VL_UNITARIO} promo={product.VL_UNITARIO_PROMOCIONAL} imgsJSON={product.JS_IMAGEM} />
              ))}
          </Row>
        </Container>
      </ContainerHome>
      <Footer/>
    </>
  );
}

export default Home;
