import React, { useState, useEffect, useRef } from 'react';
import api from "../../services/api";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import ProductCard from "../../components/ProductCard";
import Footer from "../../components/Footer";

import { ContainerInfoText, Title } from "./style.js";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ExchangesReturn = () => {

  document.title = "Troca e Devolução | Bélica Militar"

  return (
    <>
      <Header />
      <ContainerInfoText>
        <Container className="conShadown">
          <Title>Troca e Devolução</Title>
          <br />
          <p>A Bélica sugere que, antes de realizar a compra, o cliente verifique as características do produto e suas especificações, tais como tamanho, cor, quantidade, entre outros, se estão de acordo com as suas necessidades, visando assim, minimizar transtornos. </p>
          <br />
          <p>Mesmo assim, se o cliente não estiver satisfeito com a compra que realizou, poderá trocar ou devolver o produto. Caso houver dúvida, o mesmo poderá fazer contato através do e-mail atendimentovarejo@belicamilitar.com.br.</p>
          <br />
          <p>1. Procedimento para Devolu&ccedil;&atilde;o</p>

          <p>Conforme ditam as normas do C&oacute;digo de Defesa do Consumidor (www.planalto.gov.br/ccivil_03/leis/l8078.htm) e a Lei do Com&eacute;rcio Eletr&ocirc;nico (http://www.planalto.gov.br/ccivil_03/_ato2011-2014/2013/decreto/d7962.htm), o cliente que realizar compras atrav&eacute;s de lojas virtuais goza de at&eacute; 07 (sete) dias ap&oacute;s o recebimento do produto para registrar a desist&ecirc;ncia da compra;</p>

          <p></p>

          <p>Com rela&ccedil;&atilde;o ao procedimento para devolu&ccedil;&atilde;o, o cliente deve:</p>

          <p></p>

          <p>&bull; Certificar-se que o produto devolvido foi de fato fornecido pela B&eacute;lica;</p>

          <p>&bull; Comunicar ao setor de vendas o intuito da devolu&ccedil;&atilde;o no prazo de 07 (sete) dias, informando o motivo da troca ou devolu&ccedil;&atilde;o e o n&uacute;mero do pedido. O contato pode ser feito atrav&eacute;s do e-mail atendimentovarejo@belicamilitar.com.br ou atrav&eacute;s do Whatsapp +55 (47) 9901-2437;</p>

          <p>&bull; Enviar a rela&ccedil;&atilde;o dos produtos a serem devolvidos;</p>

          <p>&bull; O produto a ser devolvido deve estar sem marcas de uso e sua embalagem precisa estar preservada;</p>

          <p>&bull; A Nota Fiscal deve estar preservada, pois ter&aacute; de ser enviada juntamente com o produto;</p>

          <p>&bull; Ficar&aacute; sob responsabilidade do cliente levar o produto at&eacute; a ag&ecirc;ncia dos Correios para enviar &agrave; B&eacute;lica;</p>

          <p>&bull; Quando a mercadoria chegar &agrave; B&eacute;lica, a empresa entrar&aacute; em contato para negocia&ccedil;&atilde;o/reembolso dos valores referente &agrave; opera&ccedil;&atilde;o de devolu&ccedil;&atilde;o, conforme pol&iacute;tica de reembolso.</p>

          <p></p>

          <p>Em caso de d&uacute;vidas, o usu&aacute;rio poder&aacute; esclarec&ecirc;-las junto a Central de Atendimento pelo e-mail atendimentovarejo@belicamilitar.com.br.</p>

          <p></p>

          <p>ATEN&Ccedil;&Atilde;O: O produto que n&atilde;o atender &agrave;s condi&ccedil;&otilde;es exigidas acima, n&atilde;o ser&aacute; aceito como devolu&ccedil;&atilde;o e n&atilde;o caber&aacute; &agrave; B&eacute;lica nenhum &ocirc;nus pela devolu&ccedil;&atilde;o, bem como a devolu&ccedil;&atilde;o dos valores do pedido. O pedido ser&aacute; automaticamente remetido de volta ao endere&ccedil;o de origem. Nessas condi&ccedil;&otilde;es, a B&eacute;lica se reservar&aacute; no direito de fazer nova cobran&ccedil;a de frete. Por tanto, para maior seguran&ccedil;a e comodidade, o consumidor deve verificar se o produto atende &agrave;s suas expectativas antes de violar a embalagem.</p>

          <p></p>

          <p>1.1. Poss&iacute;veis Situa&ccedil;&otilde;es de Troca:</p>

          <p></p>

          <p>1.1.1. Produto Avariado</p>

          <p>Produtos com embalagem violada no transporte dever&atilde;o ser verificados no ato da entrega, e caso houver alguma avaria que possa gerar uma n&atilde;o conformidade, aconselha-se que n&atilde;o sejam aceitos pelo cliente.</p>

          <p></p>

          <p>1.1.2. Desist&ecirc;ncia da compra</p>

          <p>Caso o cliente receber o produto em perfeitas condi&ccedil;&otilde;es e ainda assim n&atilde;o se sentir satisfeito, o mesmo poder&aacute; solicitar o cancelamento da compra por meio de contato por e-mail ou por telefone, lembrando que ser&aacute; obrigat&oacute;rio o fornecimento do n&uacute;mero do pedido e o cumprimento dos procedimentos para devolu&ccedil;&atilde;o.</p>

          <p></p>

          <p>Caso o consumidor exercitar o direito de arrependimento, os valores eventualmente pagos durante o prazo de reflex&atilde;o ser&atilde;o devolvidos monetariamente atualizados, conforme pol&iacute;tica de reembolso.</p>

          <p></p>

          <p>N&atilde;o ser&atilde;o aceitas devolu&ccedil;&otilde;es ocasionadas por mau uso ou danos causados pelo cliente. Nestas condi&ccedil;&otilde;es, o produto ser&aacute; devolvido ao remetente sem aviso pr&eacute;vio.</p>

          <p></p>

          <p>1.1.3. Produto em Desacordo com o Pedido</p>

          <p>Caso o cliente receba um produto diferente do comprado, dever&aacute; comunicar &agrave; B&eacute;lica em at&eacute; 07 (sete) dias &uacute;teis no e-mail atendimentovarejo@belicamilitar.com.br ou pelo fone: (47) 3333-1759. Depois de analisar as condi&ccedil;&otilde;es do produto devolvido e constatar diverg&ecirc;ncia entre produto comprado e produto entregue, a B&eacute;lica enviar&aacute; o produto correto ao cliente sem nenhum custo.</p>

          <p></p>

          <p>1.1.4. Produtos com Defeito</p>

          <p>Se o produto adquirido apresentar algum defeito de fabrica&ccedil;&atilde;o que dificulte o uso correto do produto, ser&aacute; realizada a troca onde o cliente poder&aacute;:</p>

          <p></p>

          <p>a) Solicitar restitui&ccedil;&atilde;o total do valor pago (incluindo o frete);</p>

          <p>b) Solicitar o envio de um novo produto exatamente igual ao adquirido no prazo m&aacute;ximo de 30 dias corridos.</p>

          <p>Todos os custos de transporte de devolu&ccedil;&atilde;o do produto com defeito s&atilde;o de responsabilidade da B&eacute;lica, e para isso, o consumidor deve verificar os procedimentos para devolu&ccedil;&atilde;o.</p>

          <p></p>

          <p>1.2. Reembolso financeiro</p>

          <p>O reembolso de valores referentes a produtos devolvidos poder&aacute; ser feito atrav&eacute;s de dep&oacute;sito banc&aacute;rio na conta corrente do cliente. Quando for pagamento via boleto.</p>

          <p>Para isto, ao fazer contato, o cliente deve informar seus dados banc&aacute;rios - banco, conta corrente e CPF.</p>

          <p></p>

          <p>Quando for atrav&eacute;s do cart&atilde;o de cr&eacute;dito, a B&eacute;lica faz o estorno a operadora do cart&atilde;o utilizado no momento da compra,(ex: visa, master, elo, etc...), e a mesma para o cliente, dentro do prazo estabelecido pela pr&oacute;pria operadora.</p>

          <p></p>

          <p>ATEN&Ccedil;&Atilde;O: A restitui&ccedil;&atilde;o dos valores pagos s&oacute; ser&aacute; realizada ap&oacute;s o recebimento e an&aacute;lise das condi&ccedil;&otilde;es do(s) produto(s) pela equipe de controle de qualidade da B&eacute;lica.</p>

          <p></p>

          <p>1.3. Cancelamento Autom&aacute;tico de compra por parte da B&eacute;lica</p>

          <p>A B&eacute;lica reserva o direito de cancelar o pedido e libera&ccedil;&atilde;o nas situa&ccedil;&otilde;es apresentadas abaixo:</p>

          <p>- Inconsist&ecirc;ncia de dados cadastrais preenchidos no pedido;</p>

          <p>- N&atilde;o pagamento do pedido;</p>

          <p>- Duplicidade de pedidos com Produtos id&ecirc;nticos. (N&atilde;o Pagos).</p>

          <p></p>

          <p>Para estes casos a restitui&ccedil;&atilde;o de valores por iniciativa da B&eacute;lica ocorrer&aacute; nas seguintes situa&ccedil;&otilde;es:</p>

          <p></p>

          <p>No caso de impossibilidade de entrega da mercadoria adquirida, por motivo de inexist&ecirc;ncia do endere&ccedil;o de entrega como indicado pelo comprador, ou a sua n&atilde;o acessibilidade, o produto retornar&aacute; para o nosso Centro de Distribui&ccedil;&atilde;o, gerando devolu&ccedil;&atilde;o por parte da B&eacute;lica dos valores correspondentes aos pre&ccedil;os dos produtos, excluindo-se os custos com frete.</p>

          <p></p>

          <p>Caso ocorra a falta da mercadoria adquirida pelo comprador na loja virtual da B&eacute;lica, em fun&ccedil;&atilde;o de ocorr&ecirc;ncia de vendas do produto ou falta de estoque, poder&aacute; ser realizada a troca por outro produto designado pelo comprador ou haver&aacute; a devolu&ccedil;&atilde;o dos valores pagos ao mesmo. Nessas situa&ccedil;&otilde;es, o comprador ser&aacute; comunicado do ocorrido, para que o impasse possa ser resolvido da melhor forma poss&iacute;vel.</p>

          <p></p>

          <p>Garantia</p>

          <p>Todos os produtos t&ecirc;m garantia, dentro dos prazos legais e mediante a NOTA FISCAL, sobre defeitos de fabrica&ccedil;&atilde;o desde que n&atilde;o tenham sido danificados por mau uso ou n&atilde;o ter seguido as informa&ccedil;&otilde;es para conserva&ccedil;&atilde;o constantes nas etiquetas ou embalagens.</p>

          <p> - Garantia do produto Vestu&aacute;rio e Cal&ccedil;ado: 90 dias (conforme a legisla&ccedil;&atilde;o do Direito do Consumidor) - Garantia do produto em Pol&iacute;mero: 90 dias (conforme a legisla&ccedil;&atilde;o do Direito do Consumidor) + 270 dias (9 meses) de f&aacute;brica </p>

          <p>No caso de defeito de fabrica&ccedil;&atilde;o os custos de devolu&ccedil;&atilde;o e reenvio correm por conta da B&eacute;lica.</p>

          <p></p>

          <p>Necessitando de qualquer orienta&ccedil;&atilde;o na utiliza&ccedil;&atilde;o de sua garantia, o usu&aacute;rio poder&aacute; entrar em contato com a B&eacute;lica pelo (47) 3333-1759 ou pelo e-mail: atendimentovarejo@belicamilitar.com.br</p>
          <br />
        </Container>
      </ContainerInfoText>
      <Footer />
    </>
  );
}

export default ExchangesReturn;
