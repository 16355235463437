import React, { useState, useEffect, useRef } from 'react';
import api from "../../services/api";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import ProductCard from "../../components/ProductCard";
import Footer from "../../components/Footer";

import { ContainerInfoText, Title } from "./style.js";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import logo_about from '../../imgs/logos/logo_about.png'
import { initGA } from '../../services/googleAnalytics4.js';

const AboutUs = () => {

  document.title = "Sobre Nós | Bélica Militar"
  useEffect(() => {
    initGA();
  }, []);


  return (
    <>
      <Header />
      <ContainerInfoText>
        <Container className="conShadown">
          <Title>Sobre</Title>
          <div style={{textAlign: 'center', margin: '50px 0px'}}>
            <img src={logo_about} style={{maxWidth: '300px'}}/>
          </div>
          <br />
            <p>A Bélica Militar LTDA é a principal escolha em coldres, acessórios e vestuários táticos de alta qualidade e desempenho para profissionais da segurança pública e entusiastas do tiro esportivo. Estamos dedicados a fornecer produtos que superem as expectativas dos nossos clientes, proporcionando segurança e confiança em cada operação.</p>
            <p></p>
  

            <strong>Compromisso com a Qualidade:</strong>
            <p></p>
            <p>Na Bélica Militar, a qualidade é nossa prioridade. Cada produto é cuidadosamente projetado e fabricado para atender aos mais altos padrões de desempenho e durabilidade. Utilizamos materiais de primeira linha e tecnologia de ponta para garantir a excelência em cada detalhe.</p>

            

            <strong>Desenvolvimento Orientado pelo Cliente:</strong>
            <p></p>
            <p>Entendemos as necessidades únicas dos profissionais que servem e protegem nossa comunidade. Por isso, nosso processo de desenvolvimento é orientado pelo feedback dos operadores reais, combinado com uma extensa pesquisa de mercado nacional e internacional. Isso nos permite oferecer produtos verdadeiramente adaptados às demandas do campo.</p>

            

            <strong>Variedade de Produtos:</strong>
            <p></p>
            <p>Desde coldres até vestuários táticos, nossa linha de produtos abrange uma ampla gama de necessidades operacionais. Seja para policiais, militares das Forças Armadas ou CACs (Colecionadores, Atiradores e Caçadores), temos o equipamento ideal para cada missão.</p>

            

            <strong>Localização Estratégica:</strong>
            <p></p>
            <p>Nossa unidade fabril está estrategicamente localizada em Timbó, Santa Catarina, permitindo-nos atender e suprir eficientemente todo o território nacional. Estamos comprometidos em oferecer um serviço ágil e eficaz, garantindo que nossos clientes recebam seus produtos no prazo e com a qualidade esperada.</p>
            

            <p>Na Bélica Militar, nossa paixão pela excelência e compromisso com a segurança nos impulsiona a fornecer os melhores equipamentos táticos do mercado. Junte-se a nós e experimente o que é verdadeiramente superior em qualidade, desempenho e confiabilidade. Estamos aqui para apoiar você em cada operação.</p>
                    
        </Container>
      </ContainerInfoText>
      <Footer />
    </>
  );
}

export default AboutUs;
