import React, { useState, useEffect, useRef } from 'react';
import api from "../../services/api";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import ProductCard from "../../components/ProductCard";
import Footer from "../../components/Footer";

import { ContainerInfoText, Title } from "./style.js";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { initGA } from '../../services/googleAnalytics4.js';

const PrivacyPolicy = () => {

  useEffect(() => {
    initGA();
  }, []);

  document.title = "Bélica Militar - Política de Privacidade"

  return (
    <>
      <Header />
      <ContainerInfoText>
        <Container className="conShadown">
          <Title>Política de Privacidade</Title>
          <br />
          <p>A Bélica respeita a privacidade dos clientes e/ou usuários e garante a segurança das informações pessoais.</p>
          <p>Proteção de Dados Pessoais:</p>
          <p>A Bélica apresenta aos clientes e/ou usuários do site a sua política de proteção de dados, para que os mesmos possam decidir livremente se desejam fornecer à empresa os dados pessoais que são solicitados quando é realizado um pedido de compras, um cadastro, em participação de promoções, ou de qualquer outra ação de marketing desenvolvida pela empresa.</p>
          <p>As informações determinadas como “dados pessoais”, referem-se aos seguintes dados: nome; endereço; endereço de e-mail; telefone e outras informações pessoais fornecidas pelo cliente e/ou usuário.</p>
          <p>Os dados pessoais coletados pela Bélica poderão ser utilizados internamente e a empresa se responsabiliza em não promover a vender, aluguel ou transferência dos dados para terceiros.</p>
          <p>O cliente poderá solicitar à Bélica a sua exclusão do banco de dados em qualquer momento, o que implicará em não receber mais informações da empresa e de seus produtos.</p>
          <p>A Bélica reserva-se o direito de modificar a presente política para adaptá-la às alterações legislativas ou jurisprudenciais, ou relativas às práticas comerciais. Havendo qualquer modificação, a empresa se compromete em publicar em seu site, as alterações dos termos deste documento, imediatamente.</p>
          <p>Ao efetuar o pedido neste site, o cliente concorda com todos os termos aqui presentes automaticamente ao finalizar o pedido.</p>
          <br />
        </Container>
      </ContainerInfoText>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
