import React, { useState, useEffect } from 'react';
import api from "../../services/api";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { FaEdit, FaTrashAlt } from "react-icons/fa";

import { verifyLogin } from "./../../utils/verifyLogin";
import { formatCurrencyBRL } from "./../../utils/formatCurrencyBRL";

import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";

import { ContainerAddress, Title } from "./style.js";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ButtonDefault from "../../components/ButtonDefault";
import InputDefault from "../../components/InputDefault";
import SelectDefault from "../../components/SelectDefault";
import { FaSearchLocation, FaMapMarkerAlt } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';

import ModalDeleteAddress from '../../components/Modals/ModalDeleteAddress';
import { initGA } from '../../services/googleAnalytics4.js';

const Address = () => {

  useEffect(() => {
    initGA();
  }, []);


  const navigate = useNavigate();

  const [idDM001, setIdDM001] = useState(0);
  const [addressName, setAddressName] = useState("");
  const [addressType, setAddressType] = useState("1");
  const [street, setStreet] = useState("");
  const [buildingNumber, setBuildingNumber] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [complement, setComplement] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [reference, setReference] = useState("");
  const [isDefault, setIsDefault] = useState("S");
  const [contact, setContact] = useState("");
  const [status, setStatus] = useState("A");

  const [addressList, setAddressList] = useState([]);

  const [editingAddress, setEditingAddress] = useState(null);

  const [closeModelDeleteAddress, setDeleteAddress] = useState(false);
  const DeleteAddressModelControll = () => setDeleteAddress(false);
  const [detailsAddress, setDetailsAddress] = useState(0);


  useEffect(() => {
    if (!verifyLogin()) {
      navigate(`/`);
    }

    const lsClient = JSON.parse(localStorage.getItem('userDataLogin'))
    if (lsClient.ID_DM001) {
      setIdDM001(lsClient.ID_DM001)
    } else {
      navigate(`/`);
    }
    
  }, []);
  
  useEffect(() => {
    getAddressClient()
  }, [idDM001]);


  const cadNewAddress = () => {

    const cleanedZipCode = zipCode.replace(/\D/g,'');

    const dataAddressRegister = {
      "ID_DM001": idDM001,
      "TP_ENDERECO": parseInt(addressType),
      "NM_ENDERECO": addressName,
      "DS_LOGRADOURO": street,
      "NR_PREDIO": buildingNumber,
      "NM_BAIRRO": neighborhood,
      "DS_COMPLEMENTO": complement,
      "NM_CIDADE": city,
      "NR_CEP": cleanedZipCode,
      "DS_REFERENCIA": reference,
      "IN_PADRAO": isDefault,
      "NM_CONTATO": contact,
      "IN_SITUACAO": status,
    }

    api
      .post("create_client_address", dataAddressRegister)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            toast.success(response.data.message)
            setAddressName("");
            setAddressType("1");
            setStreet("");
            setBuildingNumber("");
            setNeighborhood("");
            setComplement("");
            setCity("");
            setZipCode("");
            setReference("");
            setIsDefault("S");
            setContact("");
            setStatus("A");
            getAddressClient()
          } else {
            toast.warning(response.data.message)
          }
        } else {
          toast.error(response.data.message)
        }
      })
      .catch((err) => {
        toast.error('Erro, tente novamente mais tarde!')
        console.log(err)
      });
  }

  const getAddressClient = () => {
    api
      .get(`get_all_address/${idDM001}`)
      .then((response) => {
        setAddressList(response.data)
      })
      .catch((err) => {
        toast.error('Erro, tente novamente mais tarde!')
        console.log(err)
      });
  }

  const editAddress = (address) => {
    setIdDM001(address.ID_DM001);
    setAddressName(address.NM_ENDERECO);
    setAddressType(address.TP_ENDERECO.toString());
    setStreet(address.DS_LOGRADOURO);
    setBuildingNumber(address.NR_PREDIO);
    setNeighborhood(address.NM_BAIRRO);
    setComplement(address.DS_COMPLEMENTO);
    setCity(address.NM_CIDADE);
    setZipCode(String(address.NR_CEP));
    setReference(address.DS_REFERENCIA);
    setIsDefault(address.IN_PADRAO);
    setContact(address.NM_CONTATO);
    setStatus(address.IN_SITUACAO);
      
    setEditingAddress(address);
  };
  

  const updateAddress = () => {
    const cleanedZipCode = zipCode.replace(/\D/g,'');
  
    const dataAddressRegister = {
      "ID": editingAddress.ID,
      "ID_DM001": idDM001,
      "ID_DM002": editingAddress.ID_DM002,
      "TP_ENDERECO": parseInt(addressType),
      "NM_ENDERECO": addressName,
      "DS_LOGRADOURO": street,
      "NR_PREDIO": buildingNumber,
      "NM_BAIRRO": neighborhood,
      "DS_COMPLEMENTO": complement,
      "NM_CIDADE": city,
      "NR_CEP": cleanedZipCode,
      "DS_REFERENCIA": reference,
      "IN_PADRAO": isDefault,
      "NM_CONTATO": contact,
      "IN_SITUACAO": status,
    }
  
    api
      .put("update_client_address", dataAddressRegister)
      .then((response) => {
          if (response.data.success) {
            toast.success(response.data.message)
            setAddressName("");
            setAddressType("1");
            setStreet("");
            setBuildingNumber("");
            setNeighborhood("");
            setComplement("");
            setCity("");
            setZipCode("");
            setReference("");
            setIsDefault("S");
            setContact("");
            setStatus("A");
            setEditingAddress(null);
            getAddressClient()
          } else {
            toast.warning(response.data.message)
          }
      })
      .catch((err) => {
        toast.error('Erro, tente novamente mais tarde!')
        console.log(err)
      });
  }  
  
  const DeleteAddressAccountControll = (ID_DM002) => {
    setDetailsAddress(ID_DM002)
    setDeleteAddress(true)
  }

  const DeleteAddressAccount = (ID_DM002) =>{

    const dataDelete = {
      "ID_DM002": ID_DM002
    }
    
    console.log(dataDelete)
    
    api
    .delete("delete_client_address", { data: dataDelete })
    .then((response) => {
      if(response.data.success){
        toast.success(response.data.message)
        getAddressClient()
      }else{
        toast.warning(response.data.message)
        console.log(response.data)
      }
    })
    .catch((err) => {
      console.log(err)
    });
  }

  const handleNumericBuildingNumberInput = (event) => {
    const value = event.target.value;
    setBuildingNumber(value.replace(/[^0-9]/g, ""));
  };
  
  const handleNumericZipCodeInput = (event) => {
    const value = event.target.value;
    setZipCode(value.replace(/[^0-9]/g, ""));
  };
  

  return (
    <>
      <Header />
      <ContainerAddress>
        <Container className="conShadown">
          <Title>Meus Endereços</Title>
          <br />
          <Row>
            <Col sm={6}>
              <Title><FaSearchLocation />Meus Endereços</Title>
              {addressList ?
                addressList.map((address, index) => {
                  return (
                    <>
                    <Row key={index}>
                      <Col xs={1}></Col>
                      <Col xs={10} className='addressCard'>
                        <p key={index} style={{marginBottom: '10px'}}>
                          <strong>#{address.ID_DM002} - {address.NM_ENDERECO}</strong>
                        </p>
                        <p>
                          <strong>CEP:</strong> {address.NR_CEP}
                        </p>
                        <p>
                          <strong>Cidade:</strong> {address.NM_CIDADE} - {address.NM_UF}
                        </p>
                        <p>
                          <strong>Bairro:</strong> {address.NM_BAIRRO}
                        </p>
                        <p>
                          <strong>Rua:</strong> {address.DS_LOGRADOURO}
                        </p>
                        <p>
                          <strong>Nº Prédio:</strong> {address.NR_PREDIO}
                        </p>
                        <p>
                          <strong>Referência:</strong> {address.DS_REFERENCIA}
                        </p>
                        <p>
                          <strong>Situação:</strong> {address.IN_SITUACAO === 'A' ? 'Ativo' : 'Inativo'}
                        </p>
                        <p>
                          <strong>Tipo:</strong> {address.TP_ENDERECO === '0' ? 'NFC' : address.TP_ENDERECO === '1' ? 'Entrega' : address.TP_ENDERECO === '2' ? 'Cobrança' : '-/-'}
                        </p>
                        <p>
                          <strong>Contato:</strong> {address.NM_CONTATO}
                        </p>
                        <div style={{marginTop: '-40px', float: 'right'}}>
                          <span onClick={() => editAddress(address)} className='actionBtn'><FaEdit/></span>
                          <span onClick={() => DeleteAddressAccountControll(address.ID_DM002)} className='actionBtn'><FaTrashAlt/></span>
                        </div>
                      </Col>
                    </Row>
                    </>
                  )
                })
              : <p style={{marginTop: '50px', textAlign: 'center', fontSize: '20px'}}>Nenhum endereço cadastrado!</p>}
            </Col>
            <Col sm={6}>
              <Title><FaMapMarkerAlt />Novo Endereço</Title>
              <br />
              <Row>
                <Col sm={12}>
                  <InputDefault Text="Nome do Endereço" Width={'100%'} value={addressName} onChange={e => setAddressName(e.target.value)} />
                </Col>
                <Col sm={12}>
                  <SelectDefault Text="Tipo de Endereço" Width={'100%'} value={addressType} onChange={e => setAddressType(e.target.value)}>
                    <option value="0">NFE</option>
                    <option value="1">Entrega</option>
                    <option value="2">Cobrança</option>
                  </SelectDefault>
                </Col>
                <Col sm={12}>
                  <InputDefault Text="CEP" Width={'100%'} value={zipCode} onChange={handleNumericZipCodeInput} maxLength={11} />
                </Col>
                <Col sm={12}>
                  <InputDefault Text="Cidade" Width={'100%'} value={city} onChange={e => setCity(e.target.value)} />
                </Col>
                <Col sm={12}>
                  <InputDefault Text="Bairro" Width={'100%'} value={neighborhood} onChange={e => setNeighborhood(e.target.value)} />
                </Col>
                <Col sm={12}>
                  <InputDefault Text="Rua" Width={'100%'} value={street} onChange={e => setStreet(e.target.value)} />
                </Col>
                <Col sm={12}>
                  <InputDefault Text="Número do Prédio" Width={'100%'} value={buildingNumber} onChange={handleNumericBuildingNumberInput} />
                </Col>
                <Col sm={12}>
                  <InputDefault Text="Complemento" Width={'100%'} value={complement} onChange={e => setComplement(e.target.value)} />
                </Col>
                <Col sm={12}>
                  <InputDefault Text="Referência" Width={'100%'} value={reference} onChange={e => setReference(e.target.value)} />
                </Col>
                <Col sm={12}>
                  <SelectDefault Text="Padrão" Width={'100%'} value={isDefault} onChange={e => setIsDefault(e.target.value)}>
                    <option value="S">Sim</option>
                    <option value="N">Não</option>
                  </SelectDefault>
                </Col>
                <Col sm={12}>
                  <InputDefault Text="Contato" Width={'100%'} value={contact} onChange={e => setContact(e.target.value)} />
                </Col>
                <Col sm={12}>
                  <SelectDefault Text="Situação" Width={'100%'} value={status} onChange={e => setStatus(e.target.value)}>
                    <option value="A">Ativo</option>
                    <option value="I">Inativo</option>
                  </SelectDefault>
                </Col>
                <Col sm={12}>
                  <ButtonDefault Text="Salvar" Width={"100%"} onClick={editingAddress ? updateAddress : cadNewAddress} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </ContainerAddress>
      <ToastContainer />
      <Footer />
      <ModalDeleteAddress modalStatus={closeModelDeleteAddress} closeModelFunction={DeleteAddressModelControll} DeleteAddressAccount={DeleteAddressAccount} detailsAddress={detailsAddress}/>

    </>
  );
}

export default Address;
